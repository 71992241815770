<template>
  <app-list-view
    server-side
    app="revenue"
    model="revenue"
    api-url="revenue/revenue/"
    :title="$t('menu.revenue')"
    :createTo="{ name: 'revenueCreate' }"
    :editTo="{ name: 'revenueEdit' }"
    :headers="headers"
  />
</template>

<script>
import AppListView from '@components/AppListView'

export default {
  name: 'revenueCategoryList',
  components: {
    AppListView
  },
  computed: {
    headers() {
      return [
        { text: this.$t('fields.company'), value: 'company.name' },
        { text: this.$t('fields.revenueName'), value: 'name' },
        {
          text: this.$t('fields.revenueCategory'),
          value: 'revenue_category.name'
        },
        { text: this.$t('fields.revenueType'), value: 'revenue_type.name' },
        {
          text: this.$t('fields.revenueGroup'),
          value: 'revenue_group.name'
        },
        { text: this.$t('fields.date'), value: 'date', isDate: true },
        { text: this.$t('fields.state'), value: 'state' },
        {
          text: this.$t('label.action'),
          value: 'actions',
          sortable: false,
          groupable: false,
          hideFilter: true
        }
      ]
    }
  }
}
</script>
